import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";
import DE from "./de/translation.json";
import EN from "./en/translation.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    nsSeparator: "__",
    debug: false,

    keySeparator: "__",

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    supportedLngs: ["de", "en"],
    resources: {
      en: { translation: EN },
      de: { translation: DE },
    },
    react: {
      useSuspense: false,
    },
  });

export const changeLanguage = (lang: string) => {
  i18n.changeLanguage(lang, (err) => {
    if (err) throw err;
    window.localStorage.setItem("i18nextLng", lang);
  });
};

export default i18n;
